@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./assets/font/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./assets/font/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("./assets/font/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("./assets/font/Inter-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  @apply bg-grey-50 text-grey-600 text-base leading-[23.2px];
}

.link-underline {
  @apply underline text-primary-1;
}

select {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuMzQ4MTM1IDIuMjc0MTVMNi44NjAzMyA4LjQwMzI4QzcuNTAyMDggOS4wMDcyOCA4LjUwMzEzIDkuMDA3MjggOS4xNDQ4OCA4LjQwMzI4TDE1LjY1NzEgMi4yNzQxNUMxNS45OTIyIDEuOTU4NzIgMTYuMDA4MiAxLjQzMTMzIDE1LjY5MjggMS4wOTYxOEMxNS4zNzczIDAuNzYxMDM5IDE0Ljg0OTkgMC43NDUwNTggMTQuNTE0OCAxLjA2MDQ5TDguMDAyNjEgNy4xODk2MUwxLjQ5MDQxIDEuMDYwNDlDMS4xNTUyNyAwLjc0NTA1NyAwLjYyNzg3IDAuNzYxMDM4IDAuMzEyNDQgMS4wOTYxOEMtMC4wMDI5OTA1NCAxLjQzMTMzIDAuMDEyOTkxNCAxLjk1ODcyIDAuMzQ4MTM1IDIuMjc0MTVaIiBmaWxsPSIjNjY3MTg1Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  background-size: 15px;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"]:checked + span.checkmark,
input[type="radio"]:disabled + span.checkmark {
  position: absolute;
  top: 8px;
  left: 10px;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

input[type="radio"]:checked + span.checkmark {
  background-color: #0f973d;
}

input[type="radio"]:disabled:checked + span.checkmark {
  background-color: #98a2b3;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}
.Toastify__toast-theme--light {
  border-left: 6px solid;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  width: 390px;
}

.Toastify__toast-body {
  padding: 0 12px 0 0 !important;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #101928;
}

.Toastify__toast--success {
  border-color: #04802e;
}

.Toastify__toast--error {
  border-color: #cb1a14;
}

.Toastify__toast-icon {
  width: 24px !important;
  height: 24px !important;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid;
  margin-inline-end: 12px !important;
}

.Toastify__toast--success .Toastify__toast-icon {
  border-color: #b5e3c4;
  background: #e7f6ec;
  color: #04802e !important;
}

.Toastify__toast--error .Toastify__toast-icon {
  border-color: #f2bcba;
  background: #fbeae9;
  color: #cb1a14 !important;
}

.Toastify__close-button--light {
  opacity: 1 !important;
  align-self: center !important;
  border-left: 1px solid #f0f2f5 !important;
  padding-left: 12px !important;
  min-height: 24px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__year-dropdown-container--select {
  padding: 10px;
}

.react-datepicker__header__dropdown--select select {
  background-size: 10px;
}

.react-datepicker__year-dropdown-container--select select {
  width: 60px;
}

tr:last-child .popover,
tr:nth-last-child(2) .popover {
  bottom: 28px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 92% !important;
    margin: 16px !important;
  }

  .Toastify__toast-theme--light {
    width: 100% !important;
  }

  .Toastify__close-button--light {
    min-height: 40px !important;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1216px;
  }
}
